import React from 'react';
export const Instagram = () => (
  <div className="contact-button instagram-button">
    <svg
      width="48"
      height="48"
      className="contact-svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24 17.3391C20.0946 17.3391 16.9287 20.505 16.9287 24.4104C16.9287 28.3158 20.0946 31.4817 24 31.4817C27.9053 31.4817 31.0713 28.3158 31.0713 24.4104C31.0713 20.505 27.9053 17.3391 24 17.3391Z" fill="#FFF9F2"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6196 5.00558C20.122 4.16708 27.878 4.16708 35.3804 5.00558C39.5109 5.46723 42.8424 8.72172 43.3272 12.8667C44.2243 20.5363 44.2243 28.2844 43.3272 35.9541C42.8424 40.099 39.5109 43.3535 35.3804 43.8152C27.878 44.6537 20.122 44.6537 12.6196 43.8152C8.48912 43.3535 5.15757 40.099 4.67278 35.9541C3.77574 28.2844 3.77574 20.5363 4.67278 12.8667C5.15757 8.72172 8.48911 5.46723 12.6196 5.00558ZM34.8789 11.3557C33.6772 11.3557 32.7031 12.3298 32.7031 13.5315C32.7031 14.7331 33.6772 15.7072 34.8789 15.7072C36.0805 15.7072 37.0547 14.7331 37.0547 13.5315C37.0547 12.3298 36.0805 11.3557 34.8789 11.3557ZM13.665 24.4104C13.665 18.7025 18.2921 14.0754 24 14.0754C29.7078 14.0754 34.3349 18.7025 34.3349 24.4104C34.3349 30.1182 29.7078 34.7454 24 34.7454C18.2921 34.7454 13.665 30.1182 13.665 24.4104Z" fill="#FFF9F2"/>
    </svg>
  </div>
);
