import React from 'react';
export const WhatsApp = () => (
  <div className="contact-button  whatsapp-button click">
    <svg
      width="48"
      height="48"
      className="contact-svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m14.802 8.076 3.1 7.024a2.461 2.461 0 0 1-.379 2.551l-2.012 2.343a1.708 1.708 0 0 0-.128 2.037c1.512 2.283 5.563 7.549 12.17 10.155a1.646 1.646 0 0 0 1.757-.378c.873-.91 2.057-2.467 2.85-3.545a1.734 1.734 0 0 1 2.174-.505l6.994 3.634c.528.274.707.583.675 1.445-.069 2.074-.669 4.16-2.355 5.501-7.882 6.204-20.138-1.942-25.838-8.15-5.147-5.59-12.56-16.776-3.657-22.81.829-.517 2.08-.353 3.022-.353a1.806 1.806 0 0 1 1.627 1.051Z"
        fill="#FFF9F2"
      />
    </svg>
  </div>
);
